import React, { Component } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import fonts from "../styles/fonts"
import ButtonOrangeDark from "../components/Core/Buttons/ButtonOrangeDark"
import ContentContainer from "../components/Core/Container/ContentContainer"

const StyledContainer = styled.div`
  height: calc(100vh - 98px);
  padding-top: 95px;
  h1 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 400px;
    max-height: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 28px;
  a {
    width: 100%;
    max-width: 317px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 32px;
    justify-content: center;
    a {
      max-width: 372px;
    }
  }
`

class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <Seo
          title="Page not found."
          description="The page you are looking for has been removed or relocated."
        />
        <ContentContainer>
          <StyledContainer>
            <div>
              <h1>This isn't the way to Hummingway.</h1>
              <ButtonContainer>
                <ButtonOrangeDark to={"/product/cycle-soother-patch"}>
                  Find your way
                </ButtonOrangeDark>
              </ButtonContainer>
            </div>
          </StyledContainer>
        </ContentContainer>
      </Layout>
    )
  }
}

export default NotFoundPage
